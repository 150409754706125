import React, { Fragment } from "react"
import { Layout } from "@components/Layout"
import { SEO } from "@components/SEO"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import { StaticQuery, graphql, navigate } from "gatsby"
import SignIn from "@dealer-auth/Signin"

const DealerLoginPage = () =>{
    const checkout = getCurrentCheckout()
    return (
        <Layout version="dealer" authEnabled={true}>
            <SEO title="Login" />
            <StaticQuery query={graphql`
                    {
                        site {
                            siteMetadata {
                            apiUrl
                            }
                        }
                    }
                    `}
                render={(data) => {
                return (<SignIn isDealer="true" apiUrl={data.site.siteMetadata.apiUrl}/>)
                }}></StaticQuery>
        </Layout>
    )
}

export default DealerLoginPage

