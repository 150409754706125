//Amplify Configuration file
// redirectSignIn and redirectSignOut must exactly match the cognito client app settings
// For full configuration settings refer to https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/

import Amplify from "aws-amplify"
import { AWS_AMPLIFY_AUTH, AWS_STORAGE } from "gatsby-env-variables"

export const configure_amplify = enableDebugger => {
  // console.log(AWS_AMPLIFY_AUTH)1
  Amplify.configure({
    Auth: AWS_AMPLIFY_AUTH,
    Storage: AWS_STORAGE,
  })
  if (enableDebugger) {
    Amplify.Logger.LOG_LEVEL = "DEBUG"
  }
}
