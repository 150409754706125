import React, { useEffect, useState } from "react"
/** Custom Hooks */
import Amplify, { Auth, Hub } from "aws-amplify"
import { DEFAULT_USER_AUTH } from "./consts"
import { UserStateAuth } from "./types"
import { AUTHENTICATOR_AUTHSTATE } from "./local-storage"
import { isBrowser } from "@utils/Helpers"
import { configure_amplify } from "./amplify-config"
interface IAuthContextInterface {
  auth: UserStateAuth
}

configure_amplify(true)

export const authContext = React.createContext<IAuthContextInterface>({
  auth: DEFAULT_USER_AUTH,
})
const { Provider } = authContext

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [auth, setAuth] = useState(DEFAULT_USER_AUTH)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        localStorage.setItem(AUTHENTICATOR_AUTHSTATE, "signedIn")
        setAuth({ state: "signIn", user })
      })
      .catch(err => localStorage.getItem(AUTHENTICATOR_AUTHSTATE))
      .then(cachedAuthState => cachedAuthState === "signedIn" && Auth.signOut())
  }, [])

  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      console.log("auth event listened....")
      const { event, data } = payload
      if (event === "signOut") {
        setAuth({ state: event, user: null })
        localStorage.removeItem(AUTHENTICATOR_AUTHSTATE)
        return
      } else if (event === "cognitoHostedUI") {
        localStorage.setItem(AUTHENTICATOR_AUTHSTATE, "signedIn")
        setAuth({ state: event, user: data })
      } else {
        localStorage.setItem(AUTHENTICATOR_AUTHSTATE, "signedIn")
        setAuth({ state: event, user: data })
      }
    })
  }, [])
  return (
    <Provider
      value={{
        auth,
      }}
    >
      {children}
    </Provider>
  )
}
export default AuthProvider
