import * as React from "react"
import { authContext } from "./AuthContext"
import { navigate } from "gatsby"
import { AuthPageWrapper, ModalCloseButtondDealerLogin, SignUpWrapper } from "./index.styles"
import { Form, Input, Divider, Modal } from "semantic-ui-react"

import { validateSignInForm } from "./Validator"
import ErrorMessage from "./ErrorMessage"
import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { ButtonTypes, PaymentOptions } from "@utils/Constant"
import { Auth } from "aws-amplify"
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types"
import {
  AuthFormLabel,
  AuthInputMessage,
  AuthSendCode,
  AuthSignupLink,
  AuthSignupMessage,
  AuthTitle,
  DontShowThisAgain,
  ErrorMessageContainer,
  InfoAuthSignupMessage,
  PasswordResetLink,
} from "@styles/Global.forms"
import { FcGoogle } from "react-icons/fc"
import { FaFacebookF } from "react-icons/fa"
import { useState } from "react"
import { getCurrentCheckout, getShowModalResetPassword, saveDealerAddressToLocalStorage, saveIsDealerOrderToLocalStorage, savePaymentTypeToLocalStorage, savePersonalDetailsToLocalStorage, saveShowModalResetPassword, saveTokenToLocalStorage } from "@redux/localStorage/checkout"
import { updateIsDealerOrder, updatePersonalDetails } from "@redux/actions/checkout"
import { DealerService } from "@services/DealerService"
import { Paragraph1 } from "@styles/Global.styles"
import { InfoModalButton } from "@components/InfoModal/InfoModal.styles"
import { ModalCloseButtonMobile } from "@components/PromotionModal/PromotionModal.styles"

const SignIn = (props: any) => {
  const { auth } = React.useContext(authContext)
  const {isDealer, apiUrl } = props

  return (
    <AuthPageWrapper>
      <SignUpWrapper>
        <AuthTitle>Dealer Login</AuthTitle>
        <SignInForm isDealer={isDealer} apiUrl={apiUrl}/>


        {!isDealer && <React.Fragment>
        <Divider horizontal style={{ color: "#666" }}>
          Or
        </Divider>
        <div style={{ textAlign: "center", paddingTop: "15px" }}>
          <KpButton
            id="signin-submit"
            onClick={() => {
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google,
              })
            }}
            color={theme.brand.colors.darkGrey}
            buttonType={ButtonTypes.Secondary}
            fullWidth="mobile tablet computer"
            textColor={theme.brand.colors.black}
            type="submit"
          >
            <div style={{ width: "100%", paddingLeft: "15px" }}>
              <FcGoogle size={24} style={{ float: "left" }} />
              <span style={{ lineHeight: "24px" }}>Login with Google</span>
            </div>
          </KpButton>
        </div>
        <div style={{ textAlign: "center", paddingTop: "15px" }}>
          <KpButton
            id="signin-submit"
            onClick={() => {
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Facebook,
              })
            }}
            color={theme.brand.colors.darkGrey}
            buttonType={ButtonTypes.Secondary}
            fullWidth="mobile tablet computer"
            type="submit"
          >
            <div style={{ width: "100%", paddingLeft: "15px" }}>
              <FaFacebookF
                size={24}
                style={{ float: "left", color: "#1877f2" }}
              />{" "}
              <span style={{ lineHeight: "24px" }}>Login with Facebook</span>
            </div>
          </KpButton>
        </div>
        </React.Fragment>}
      </SignUpWrapper>
    </AuthPageWrapper>
  )
}
export default SignIn

const SignInForm = (props: any) => {

  const {isDealer, apiUrl } = props

  const [account, setAccount] = useState({ email: "", password: "" })
  const [error, setError] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [resendCode, setResendCode] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  // const [isVerified, setIsVerified] = React.useState(false)
  // const [isRegistered, setIsRegistered] = React.useState(false)
  // const [signupFirst, setSignupFirst] = React.useState(false)
  const [isLoginDisabled, setIsLoginDisabled] = React.useState(false)
  const [isLoginSusscess, setIsLoginSusscess] = React.useState(false)
  const [salesPersonId, setSalesPersonId] = React.useState("")
  const [salesPersonEmail, setSalesPersonEmail] = React.useState("")
  const [showModal, setShowModal] = React.useState(getShowModalResetPassword())

  const updateAccount = (account: { email: string; password: string }) => {
    setAccount(account)
    setError("")
    setResendCode(false)
    setCodeSent(false)
    setIsLoginDisabled(false)
    setSalesPersonId("")
    setSalesPersonEmail("")
  }

  const resend = () => {
    Auth.resendSignUp(account.email).then(res => {
      console.log(res)
      setCodeSent(true)
      setError("")
    })
  }

  const handleSignIn = (e: any) => {
    e.preventDefault()
    if (validateSignInForm(account.email, account.password, setError)) {
      setLoading(true)
      Auth.signIn(account.email, account.password)
        .then(res => {
          if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
            navigate("/reset-password/")
          } else {
            setLoading(false)
            navigate("/app/listings")
            return
          }
        })
        .catch(exception => {
          if (exception.code == "UserNotConfirmedException") {
            setResendCode(true)
          }
          setError(exception.message)
          setLoading(false)
        })
    }
  }

  const onChangeSPID = (spid: string) => {
    setSalesPersonId(spid)
  }

  const handleDealerSignInUsingSPID = () => {
    DealerService.login(apiUrl, salesPersonEmail.trim(), salesPersonId.trim())
    .then(response => {
      if(response.Status === 200){
        setLoading(false)
        var checkout = getCurrentCheckout()
        checkout.isDealerOrder = true
        checkout.dealerDetails = {
          dealerId: response.Data.DealerId,
          dealerName: response.Data.DealerName,
          salesPersonEmail: response.Data.SalesPersonEmail,
          salesPersonId: response.Data.SalesPersonId,
          salesPersonName: response.Data.SalesPersonName
        }
        checkout.dealerAddress = {
          fullAddress: response.Data.FullAddress,
          street: response.Data.Street,
          building: response.Data.Building,
          unit: response.Data.Unit,
          suburb: response.Data.Suburb,
          city: response.Data.City,
          postCode: response.Data.PostCode,
          isManualAddress: true
        }
        savePersonalDetailsToLocalStorage(checkout)
        saveIsDealerOrderToLocalStorage(checkout.isDealerOrder)
        saveDealerAddressToLocalStorage(checkout.dealerAddress)
        savePaymentTypeToLocalStorage(PaymentOptions.CreditCard)
        navigate("/dealer-promotions")
      } else {
        setLoading(false)
        setError(response.message)
      }
    })
    .catch(exception => {
        setError(exception.message)
        setLoading(false)
    })
  }

  const handleDealerSignInModal = () => {
    setShowModal(false);
  }

  const handleHideModalPermanently = () => {
    setShowModal(false);
    saveShowModalResetPassword('False')
  }


  const handleDealerSignIn = (e: any) => {
    e.preventDefault()
    if (validateSignInForm(account.email, account.password, setError)) {
      setLoading(true)
      Auth.signIn(account.email, account.password)
        .then(res => {
          saveTokenToLocalStorage(res.signInUserSession.accessToken.jwtToken)
          if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
            navigate("/reset-password/")
          } else {
            var email = res.attributes.email
            DealerService.updateDealerRegistration(apiUrl, email.trim())
            .then(rsp => {
              if(rsp.Status === 200 || rsp.Status === 500){
                setSalesPersonEmail(email.trim())
                DealerService.getDealer(apiUrl, email.trim())
                .then(response => {
                  if(response.Status === 200){
                    setLoading(false)
                    var data = response.Data[0]
                    var checkout = getCurrentCheckout()
                    checkout.isDealerOrder = true
                    checkout.dealerDetails = {
                      dealerId: data.DealerId,
                      dealerName: data.DealerName,
                      salesPersonEmail: data.SalesPersonEmail,
                      salesPersonId: data.SalesPersonId,
                      salesPersonName: data.SalesPersonName,
                      distributionId: data.CommissionDistribution
                    }
                    checkout.dealerAddress = {
                      fullAddress: data.FullAddress,
                      street: data.Street,
                      building: data.Building,
                      unit: data.Unit,
                      suburb: data.Suburb,
                      city: data.City,
                      postCode: data.PostCode,
                      isManualAddress: true
                    }
                    savePersonalDetailsToLocalStorage(checkout)
                    saveIsDealerOrderToLocalStorage(checkout.isDealerOrder)
                    saveDealerAddressToLocalStorage(checkout.dealerAddress)
                    savePaymentTypeToLocalStorage(PaymentOptions.CreditCard)
                    navigate("/dealer-promotions")
                  } else {
                    if(response.Status === 500){
                      setIsLoginDisabled(true)
                    }
                    setLoading(false)
                    setError(response.Message)
                  }
                })
                .catch(exception => {
                    setError(exception.message)
                    setLoading(false)
                })
              } else {
                setLoading(false)
              }
            })
            .catch(exp => {
                setError(exp.message)
                setLoading(false)
            })
            return
          }
        })
        .catch(exception => {
          if (exception.code == "UserNotConfirmedException") {
            setResendCode(true)
          }
          setError(exception.message)
          setLoading(false)
        })
    }
  }


  return (
    <React.Fragment>
    {isLoginSusscess?
    <React.Fragment>
      <Form>
        <Form.Field>
            <AuthFormLabel>SALESPERSON ID</AuthFormLabel>
          <Input
            type="salespersonid"
            name="salespersonid"
            value={salesPersonId}
            id="salespersonid"
            placeholder="KP12345"
            onChange={e => onChangeSPID(e.target.value)}
          />
        </Form.Field>
        <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
            <KpButton
              id="continue-submit"
              color={theme.brand.colors.green}
              fullWidth="mobile tablet computer"
              buttonType={ButtonTypes.Primary}
              loading={loading}
              onClick={() => handleDealerSignInUsingSPID()}
            >
              Continue
            </KpButton>
        </Form.Field>

        <ErrorMessageContainer>
                <p> Forgot your Sales Person ID? Please call our dealer team on <a href="tel:0800650111">0800 650 111</a> or email us at <a href="mailto:dealers@kiwiplates.nz">dealers@kiwiplates.nz</a>
                </p>
                </ErrorMessageContainer>
        {error != "" && <div style={{ textAlign: "center", paddingTop: "10px" }}>
            <AuthSignupMessage>
              Invalid Salesperson Id.
            </AuthSignupMessage>
        </div>}
      </Form>
    </React.Fragment>:
    <React.Fragment>
      <Form onSubmit={ isDealer ? handleDealerSignIn : handleSignIn}>
        <Form.Field>
          <AuthFormLabel>EMAIL</AuthFormLabel>
          <Input
            type="email"
            name="email"
            value={account.email}
            id="email"
            placeholder="yourname@email.com"
            onChange={e => updateAccount({ ...account, email: e.target.value })}
            autoComplete="new-password"
            className={error && "red-border"}
          />
        </Form.Field>
        <React.Fragment>
          <Form.Field>
            <AuthFormLabel>PASSWORD</AuthFormLabel>
            <AuthInputMessage>
              <PasswordResetLink to="/dealer-forgot-password">
                Forgot your password?
              </PasswordResetLink>
            </AuthInputMessage>
            <Input
              type="password"
              name="password"
              value={account.password}
              id="password"
              className={error && "red-border"}
              onChange={e =>
                updateAccount({ ...account, password: e.target.value })
              }
              autoComplete="new-password"
            />
          </Form.Field>

          {error && <ErrorMessage errorMessage={error} />}
          {resendCode && (
            <div style={{ textAlign: "center", paddingTop: "10px" }}>
              <AuthSignupMessage>
                Don't verify your email address?
              </AuthSignupMessage>
              <AuthSendCode onClick={resend}>Re-send!</AuthSendCode>
            </div>
          )}
          {codeSent && (
            <div style={{ textAlign: "center", paddingTop: "10px" }}>
              <AuthSignupMessage>
                Please check your email to verify your email address.
              </AuthSignupMessage>
            </div>
          )}

          <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
            <KpButton
              id="signin-submit"
              color={theme.brand.colors.green}
              fullWidth="mobile tablet computer"
              buttonType={ButtonTypes.Primary}
              loading={loading}
              type="submit"
              disabled={isLoginDisabled}
            >
              {loading ? "Loading..." : "Log in"}
            </KpButton>
          </Form.Field>
        </React.Fragment>
      </Form>
      <div style={{ textAlign: "center", paddingTop: "10px" }}>
        <p>Don't have an account? Or having trouble logging in? Please call our dealer team on <a href="tel:0800650111">0800 650 111</a> or email us at <a href="mailto:dealers@kiwiplates.nz">dealers@kiwiplates.nz</a></p>
      </div>
    </React.Fragment>}

    <Modal open={showModal} onClose={()=>handleDealerSignInModal()} className="dealer-login-modal-announcement">
        <ModalCloseButtondDealerLogin name='x' size='large' color="grey" onClick={()=>handleDealerSignInModal()} float="right"/>
        <Modal.Content>
          <AuthTitle>IMPORTANT NOTICE</AuthTitle>

          <div style={{ textAlign: "center", paddingTop: "10px",marginBottom: "30px" }}>
            <p>
            There is a new Login process. Please create a new password using your existing email.
            </p>
          </div>
          <KpButton
              id="signin-submit"
              color={theme.brand.colors.green}
              fullWidth="mobile tablet computer"
              buttonType={ButtonTypes.Primary}
              loading={loading}
              type="button"
              onClick={()=>navigate("/dealer-forgot-password/")}
            >
              RESET YOUR PASSWORD
            </KpButton>
            <DontShowThisAgain onClick={()=>handleHideModalPermanently()} >
              Dont show this again.
            </DontShowThisAgain>
        </Modal.Content>

    </Modal>


    </React.Fragment>
  )
}
