import React from "react";
import styled from "@emotion/styled";

type ErrorMessageProps = {
  errorMessage: Error | string;
};
const ErrorMessageContainer = styled.p`
  text-align: center;
  margin-top: 18px !important;
  margin-bottom: 5px !important;
  color: #D70826;
`;
const ErrorMessage: React.FC<ErrorMessageProps> = ({
  errorMessage,
}) => {
  return <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>;
};
export default ErrorMessage;